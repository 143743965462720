import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`19th April 2022`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Armeria `}<a parentName="p" {...{
            "href": "type://Server:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/Server.html"
          }}>{`type://Server`}</a>{` now exposes the metrics about TLS handshake results. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4191"
          }}>{`#4191`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`armeria_server_tls_handshakes_total{
  cipher_suite=<the negotiated TLS cipher suite>,
  common_name=<the common name in TLS certificate>,
  protocol=<h1 or h2>,
  result=<success or failure>,
  tls_protocol=<TLS protocol name, e.g. TLSv1.3>
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can use the `}<a parentName="p" {...{
            "href": "type://@Delimiter:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Delimiter.html"
          }}>{`type://@Delimiter`}</a>{` annotation in an annotated service to specify the delimiter of
a query parameter or header. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4060"
          }}>{`#4060`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`public class MyAnnotatedService {
  // Given the request:
  //   GET /api/v1/users?ids=1,2,3
  // Armeria will inject:
  //   List.of(1, 2, 3)
  // into:
  //   List<Integer> ids
  @Get("/api/v1/users")
  public List<User> getUsers(@Param @Delimiter(",") List<Integer> ids) {
    ...
  }
}
`}</code></pre>
        <ul parentName="li">
          <li parentName="ul">{`You can also specify a delimiter programmatically with `}<a parentName="li" {...{
              "href": "type://AnnotatedServiceBindingBuilder#queryDelimiter(String):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/AnnotatedServiceBindingBuilder.html#queryDelimiter(java.lang.String)"
            }}>{`type://AnnotatedServiceBindingBuilder#queryDelimiter(String)`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use the no-op `}<inlineCode parentName="p">{`subscribe()`}</inlineCode>{` to drain elements from a `}<a parentName="p" {...{
            "href": "type://StreamMessage:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html"
          }}>{`type://StreamMessage`}</a>{`  `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4145"
          }}>{`#4145`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4185"
          }}>{`#4185`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// The following statement prints 1, 2 and 3.
StreamMessage
  .of(1, 2, 3)
  .peek(value -> System.out.println(value))
  .subscribe().join(); // 👈👈👈
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use the `}<inlineCode parentName="p">{`response_body`}</inlineCode>{` option in gRPC-JSON transcoding. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4052"
          }}>{`#4052`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4132"
          }}>{`#4132`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4174"
          }}>{`#4174`}</a></p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`This option is useful when you need to make a certain response field as a response body.
For example, the following Protobuf definition allows you to send a JSON array body
whose values are sourced from `}<inlineCode parentName="p">{`GetNumbersResponse.myNumberArray`}</inlineCode>{`:`}</p>
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-protobuf"
              }}>{`service MyService {
  rpc GetNumbers(GetNumbersRequest) returns (GetNumbersResponse) {
    option (google.api.http) = {
      get: "/api/v1/numbers"
      response_body: "my_number_array" // 👈👈👈
    };
  }
}

message GetNumbersRequest {}
message GetNumbersResponse {
  repeated string my_number_array = 1; // 👈👈👈
}
`}</code></pre>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use the `}<a parentName="p" {...{
            "href": "type://@Decorator:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Decorator.html"
          }}>{`type://@Decorator`}</a>{` annotation to apply decorators to your gRPC stubs. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3967"
          }}>{`#3967`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4041"
          }}>{`#4041`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`@LoggingDecorator // 👈👈👈
public class GreeterImpl extends GreeterGrpc.GreeterImplBase {
  @Override
  @RateLimitingDecorator(/* requestsPerSec */ 10.0) // 👈👈👈 
  public void sayHello(
    HelloRequest req,
    StreamObserver<HelloReply> responseObserver
  ) {
    responseObserver.onNext(
      HelloReply
        .newBuilder()
        .setMessage(req.getName())
        .build()
    );
    responseObserver.onCompleted();
  }
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "type://AbstractUnaryGrpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/protocol/AbstractUnaryGrpcService.html"
          }}>{`type://AbstractUnaryGrpcService`}</a>{` and unframed `}<a parentName="p" {...{
            "href": "type://GrpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcService.html"
          }}>{`type://GrpcService`}</a>{` now include the stack trace for failed
requests when `}<inlineCode parentName="p">{`verboseResponses`}</inlineCode>{` option is enabled for easier debuggability. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4114"
          }}>{`#4114`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4171"
          }}>{`#4171`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now specify query parameters additively by calling `}<a parentName="p" {...{
            "href": "type://WebClientRequestPreparation#queryParam(String,Object):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClientRequestPreparation.html#queryParam(java.lang.String,java.lang.Object)"
          }}>{`type://WebClientRequestPreparation#queryParam(String,Object)`}</a>{`
`}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4173"
          }}>{`#4173`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// Send GET /api/v1/greet?foo=1&bar=2
WebClient
  .of().prepare()
  .get("/api/v1/greet")
  .queryParam("foo", "1")
  .queryParams(Map.of("bar", "2")) // 👈👈👈
  .execute()
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now override the global `}<a parentName="p" {...{
            "href": "type://Flags:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Flags.html"
          }}>{`type://Flags`}</a>{` by implementing your own `}<a parentName="p" {...{
            "href": "type://FlagsProvider:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/FlagsProvider.html"
          }}>{`type://FlagsProvider`}</a>{` and
loading it via Java SPI. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4093"
          }}>{`#4093`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4151"
          }}>{`#4151`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`package com.example.providers;

// Add the following text file to your classpath or JAR file:
//
// $ cat META-INF/services/com.linecorp.armeria.common.FlagsProvider
// com.example.providers.MyFlagsProvider
//
public class MyFlagsProvider implements FlagsProvider {
  @Override
  public int priority() {
    // The provider with higher value will be evaluated first.
    return 100;
  }

  @Override
  public int numCommonBlockingTaskThreads() {
      return 100;
  }
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`When using `}<inlineCode parentName="p">{`armeria-graphql`}</inlineCode>{`, you can now retrieve the current `}<a parentName="p" {...{
            "href": "type://ServiceRequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html"
          }}>{`type://ServiceRequestContext`}</a>{` with
`}<a parentName="p" {...{
            "href": "type://GraphqlServiceContexts#get(DataFetchingEnvironment):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/graphql/GraphqlServiceContexts.html#get(graphql.schema.DataFetchingEnvironment)"
          }}>{`type://GraphqlServiceContexts#get(DataFetchingEnvironment)`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4201"
          }}>{`#4201`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`new DataFetcher<>() {
  @Override
  public String get(DataFetchingEnvironment env) throws Exception {
    ServiceRequestContext ctx = GraphqlServiceContexts.get(env);
    ...
  }
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now disable the warning message about masked routes when building a `}<a parentName="p" {...{
            "href": "type://Server:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/Server.html"
          }}>{`type://Server`}</a>{` by specifying
the `}<inlineCode parentName="p">{`-Dcom.linecorp.armeria.reportMaskedRoutes=false`}</inlineCode>{` JVM option. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4086"
          }}>{`#4086`}</a></p>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`armeria-graphql`}</inlineCode>{` doesn't use deprecated `}<inlineCode parentName="li">{`graphql-java`}</inlineCode>{` API anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4201"
        }}>{`#4201`}</a></li>
      <li parentName="ul">{`Less verbose and clearer TLS handshake logging `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4181"
        }}>{`#4181`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`JSON-gRPC transcoding now works even if you bind a `}<a parentName="li" {...{
          "href": "type://GrpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcService.html"
        }}>{`type://GrpcService`}</a>{` with path prefix. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4086"
        }}>{`#4086`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4215"
        }}>{`#4215`}</a>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server
  .builder()
  .route()
    .pathPrefix("/api") // 👈👈👈
    .build(
      GrpcService
        .builder()
        .enableHttpJsonTranscoding(true)
        .addService(...)
        .build()
    )
  .build()
`}</code></pre>
      </li>
      <li parentName="ul">{`You now get the `}<inlineCode parentName="li">{`HeaderListSizeException`}</inlineCode>{` as expected when the length of request headers is too long. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4180"
        }}>{`#4180`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://EurekaEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/eureka/EurekaEndpointGroup.html"
        }}>{`type://EurekaEndpointGroup`}</a>{` now continues refreshing itself even if it failed to refresh once. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4206"
        }}>{`#4206`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://HealthCheckedEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/healthcheck/HealthCheckedEndpointGroup.html"
        }}>{`type://HealthCheckedEndpointGroup`}</a>{` now leaves a detailed error message if all endpoints are unhealthy
on the first check. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4200"
        }}>{`#4200`}</a></li>
      <li parentName="ul">{`Fixed a rare infinite loop in `}<a parentName="li" {...{
          "href": "type://EndpointSelectionStrategy#rampingUp():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointSelectionStrategy.html#rampingUp()"
        }}>{`type://EndpointSelectionStrategy#rampingUp()`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4219"
        }}>{`#4219`}</a></li>
      <li parentName="ul">{`The search domain DNS resolver no longer incorrectly resolves a search domain that starts with a dot.
`}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4195"
        }}>{`#4195`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4196"
        }}>{`#4196`}</a></li>
      <li parentName="ul">{`Fixed a bug where Armeria server doesn't log the remote address when TLS handshake fails. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4181"
        }}>{`#4181`}</a></li>
      <li parentName="ul">{`Reduced the cardinality of TLS certificate expiry metrics by using a hostname pattern instead of a hostname
as a label. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4191"
        }}>{`#4191`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://TomcatService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/tomcat/TomcatService.html"
        }}>{`type://TomcatService`}</a>{` now records the request start time of `}<inlineCode parentName="li">{`CoyoteRequest`}</inlineCode>{` correctly. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4182"
        }}>{`#4182`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4183"
        }}>{`#4183`}</a></li>
      <li parentName="ul">{`You don't get an exception anymore when parsing a multipart request with an empty body. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4175"
        }}>{`#4175`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4179"
        }}>{`#4179`}</a></li>
      <li parentName="ul">{`You don't get the noisy warning message about double-completion of future, which occurs when a user
misconfigured a `}<a parentName="li" {...{
          "href": "type://ClientFactory:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactory.html"
        }}>{`type://ClientFactory`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4191"
        }}>{`#4191`}</a></li>
    </ul>
    <h2 {...{
      "id": "-documentation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-documentation",
        "aria-label": " documentation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📃 Documentation`}</h2>
    <ul>
      <li parentName="ul">{`Our build script examples in the website now include Gradle Kotlin DSL. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4106"
        }}>{`#4106`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4176"
        }}>{`#4176`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-deprecations",
        "aria-label": "️ deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🏚️ Deprecations`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://Flags#verboseExceptionSamplerSpec():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Flags.html#verboseExceptionSamplerSpec()"
        }}>{`type://Flags#verboseExceptionSamplerSpec()`}</a>{` has been deprecated in favor of
`}<a parentName="li" {...{
          "href": "type://Flags#verboseExceptionSampler():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Flags.html#verboseExceptionSampler()"
        }}>{`type://Flags#verboseExceptionSampler()`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4093"
        }}>{`#4093`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4151"
        }}>{`#4151`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul">{`The TLS certificate expiry metrics were renamed from
`}<inlineCode parentName="li">{`armeria.server.certificate.*`}</inlineCode>{` to `}<inlineCode parentName="li">{`armeria.server.tls.certificate.*`}</inlineCode>{`
for consistency. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4191"
        }}>{`#4191`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Also, the `}<inlineCode parentName="li">{`hostname`}</inlineCode>{` label has been replaced with `}<inlineCode parentName="li">{`hostname_pattern`}</inlineCode>{` to reduce the cardinality.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`The return type of `}<a parentName="li" {...{
          "href": "type://Flags#requestContextStorageProvider():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Flags.html#requestContextStorageProvider()"
        }}>{`type://Flags#requestContextStorageProvider()`}</a>{` was changed from `}<inlineCode parentName="li">{`String`}</inlineCode>{` to
`}<a parentName="li" {...{
          "href": "type://RequestContextStorageProvider:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContextStorageProvider.html"
        }}>{`type://RequestContextStorageProvider`}</a>{`, so that a user knows which provider will be used exactly. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4093"
        }}>{`#4093`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4151"
        }}>{`#4151`}</a></li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Brave 5.13.7 → 5.13.8`}</li>
      <li parentName="ul">{`Brotli4j 1.6.0 → 1.7.1`}</li>
      <li parentName="ul">{`Bucket4j 7.3.0 → 7.4.0`}</li>
      <li parentName="ul">{`Dropwizard 2.0.28 → 2.0.29`}</li>
      <li parentName="ul">{`gRPC 1.45.0 → 1.45.1`}</li>
      <li parentName="ul">{`Jackson 2.13.2 → 2.13.2.1`}</li>
      <li parentName="ul">{`java-jwt 3.19.0 → 3.19.1`}</li>
      <li parentName="ul">{`Jetty 9.4.45 → 9.4.46`}</li>
      <li parentName="ul">{`Kotlin 1.6.10 → 1.6.20`}
        <ul parentName="li">
          <li parentName="ul">{`Coroutines 1.6.0 → 1.6.1`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Micrometer 1.8.4 → 1.8.5`}</li>
      <li parentName="ul">{`Netty 4.1.75 → 4.1.76`}</li>
      <li parentName="ul">{`Reactor 3.4.16 → 3.4.17`}</li>
      <li parentName="ul">{`Scala 3.1.1 → 3.1.2`}</li>
      <li parentName="ul">{`Spring Boot 2.6.5 → 2.6.6`}</li>
      <li parentName="ul">{`Tomcat 9.0.56 → 9.0.62, 8.5.77 → 8.5.78`}</li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['Jsing', 'KarboniteKream', 'TheWeaVer', 'aboivin', 'alexc-db', 'anuraaga', 'clatisus', 'eisig', 'ghkim3221', 'heowc', 'ikhoon', 'jrhee17', 'jupiny', 'klurpicolo', 'kojilin', 'ks-yim', 'minwoox', 'natsumehu', 'syleeeee', 'ta7uw', 'techno', 'timothy-xflowpay', 'tokuhirom', 'trustin', 'wooseongshin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      